<template>
  <div>
    <router-view/>
  </div>
</template>

<style>
html,body {
  scroll-behavior: smooth;
  background: #FFFFFF;
  padding: 0;
  margin: 0;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently */
}
</style>
