import { createStore } from "vuex";
import axios from "axios";
//import { resolve } from "core-js/fn/promise";


axios.defaults.baseURL = "https://live.stage.micronagritech.com/api/v2";
// axios.defaults.baseURL = "http://dev.stage.micronagritech.com/api/v2";
// axios.defaults.baseURL = "http://localhost:3000/api/v2";


if (localStorage.MicronToken) {
  axios.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${localStorage.MicronToken}`;
}

export const store = new createStore({
  modules: {
    User: {
      namespaced: true,
      state: {
        loggedin: false,
        gotinfo: false,
        token: null,
        info: {
          name: "",
          is_staff: false,
          is_qc: false,
          is_tester: false
        },
        organisation: false,
        business: false,
        organisations: [],
        businesses: [],
      },
      mutations: {
        SET_AUTH(state, info) {
          state.token = info.token;
          localStorage.setItem("MicronToken", info.token);
          state.loggedin = true;
        },
        SET_USERINFO(state, info) {
          if (state.token == null) {
            state.token = localStorage.MicronToken;
          }
          state.loggedin = true;
          state.gotinfo = true;
          state.info = info;
        },
        CLEAR_INFO(state) {
          localStorage.clear("MicronToken");
          state.token = null;
          state.loggedin = false;
          state.gotinfo = false;
          state.info = { name: "", is_staff: false, is_qc: false, is_tester: false };
        },
        SET_ORG(state, info) {
          state.organisation = true;
          state.organisations = info;
        },
        SET_BUSINESS(state, info) {
          state.business = true;
          state.businesses = info;
        },
      },
      actions: {
        /**
         * Issue login request with Micron Cloud
         * @param {JSON Obj} form Object containing information.
         * @param {email} form.email email address
         * @param {string} form.password password
         */
        User_Login({ commit }, form) {
          return new Promise((resolve, reject) => {
            if ((form.email !== "", form.password !== "")) {
              axios
                .post("/users/login/", {
                  email: form.email,
                  password: form.password,
                })
                .then(({ status, data }) => {
                  if (status == 200) {
                    commit("SET_AUTH", { token: data.token });
                    resolve(data);
                  } else {
                    reject(data);
                  }
                })
                .catch((err) => {
                  if (err.response) {
                    reject(err.response.data);
                  } else if (err.request) {
                    console.log(err.request);
                    reject({
                      messages: [
                        "Sorry, something wen't wrong with that request.",
                      ],
                    });
                  } else {
                    console.log(err.message);
                    reject({ messages: [err.message] });
                  }
                });
            } else {
              reject({
                status: 400,
                messages: ["Email & Password fields are required."],
              });
            }
          });
        },
        /**
         * Issue password recovery.
         * @param {email} form.email email address
         */
        ForgotPassword(_, form) {
          return new Promise((resolve, reject) => {
            if (form.email !== "") {
              axios
                .post("/users/forgotpassword/", { email: form.email })
                .then(({ status, data }) => {
                  if (status == 200) {
                    resolve(data);
                  } else {
                    reject(data);
                  }
                })
                .catch((err) => {
                  if (err.response) {
                    reject(err.response.data);
                  } else if (err.request) {
                    console.log(err.request);
                    reject({
                      messages: [
                        "Sorry, something wen't wrong with that request.",
                      ],
                    });
                  } else {
                    console.log(err.message);
                    reject({ messages: [err.message] });
                  }
                });
            } else {
              reject({
                status: 400,
                messages: ["Email & Password fields are required."],
              });
            }
          });
        },
        /**
         * Change user password upon recovery.
         * @param {*} _
         * @param {*} form containing key,passwords.
         */
        RecoveryChangePassword(_, form) {
          return new Promise((resolve, reject) => {
            if (
              form.email !== "" &&
              form.password !== "" &&
              form.confirm_password !== ""
            ) {
              axios
                .put("/users/recovery/changepassword/", form)
                .then(({ status, data }) => {
                  if (status == 200) {
                    resolve(data);
                  } else {
                    reject(data);
                  }
                })
                .catch((err) => {
                  if (err.response) {
                    reject(err.response.data);
                  } else if (err.request) {
                    console.log(err.request);
                    reject({
                      messages: [
                        "Sorry, something wen't wrong with that request.",
                      ],
                    });
                  } else {
                    console.log(err.message);
                    reject({ messages: [err.message] });
                  }
                });
            } else {
              reject({
                status: 400,
                messages: ["Email & Password fields are required."],
              });
            }
          });
        },
        /**
         * Check user is authenticated by checking session storage.
         */
        CheckAuth() {
          return new Promise((resolve, reject) => {
            if (localStorage.MicronToken) {
              resolve("Authenticated. Token Located.");
              // Run a query to check token is valid vs hostname & more.
            } else {
              reject("Not authenticated. Token Located.");
            }
          });
        },
        /**
         * Get user information via get request with token.
         */
        UserInformation({ commit, dispatch, state }) {
          return new Promise((resolve, reject) => {
            if (state.gotinfo == false) {
              axios
                .get("/users/information", {
                  headers: {
                    Authorization: `Bearer ${
                      state.token || localStorage.MicronToken
                    }`,
                  },
                })
                .then(({ data }) => {
                  commit("SET_USERINFO", data.data);
                  dispatch("UserOrganisationsAndBusinesses")
                    .then(() => {
                      resolve("OK");
                    })
                    .catch(() => {
                      reject("Not OK");
                    });
                })
                .catch((err) => {
                  console.log(err);
                  reject("Not OK");
                });
            } else {
              resolve("OK");
            }
          });
        },
        /**
         * Log out user.
         */
        Logout({ commit }) {
          return new Promise((resolve) => {
            commit("CLEAR_INFO");
            resolve();
          });
        },
        /**
         * Find users oganisations
         */
        UserOrganisationsAndBusinesses({ commit, state }) {
          return new Promise((resolve, reject) => {
            axios
              .get("/organisations/list/", {
                headers: {
                  Authorization: `Bearer ${
                    state.token || localStorage.MicronToken
                  }`,
                },
              })
              .then(({ data }) => {
                commit("SET_ORG", data.data);
              })
              .catch((err) => {
                console.log(err);
                if (err.response) {
                  reject(err.response.data);
                } else if (err.request) {
                  console.log(err.request);
                  reject({
                    messages: [
                      "Sorry, something wen't wrong with that request.",
                    ],
                  });
                } else {
                  console.log(err.message);
                  reject({ messages: [err.message] });
                }
              });

            axios
              .get("/businesses/list/", {
                headers: {
                  Authorization: `Bearer ${
                    state.token || localStorage.MicronToken
                  }`,
                },
              })
              .then(({ data }) => {
                commit("SET_BUSINESS", data.data);
              })
              .catch((err) => {
                console.log(err);
                if (err.response) {
                  reject(err.response.data);
                } else if (err.request) {
                  console.log(err.request);
                  reject({
                    messages: [
                      "Sorry, something wen't wrong with that request.",
                    ],
                  });
                } else {
                  console.log(err.message);
                  reject({ messages: [err.message] });
                }
              });
          });
        },
        //
        UserActivateAccount(_, email) {
          return new Promise((resolve, reject) => {
            axios
              .post("/users/confirm-email/", email)
              .then(({ data }) => {
                resolve(data);
              })
              .catch((err) => {
                console.log(err);
                if (err.response) {
                  reject(err.response.data);
                } else if (err.request) {
                  console.log(err.request);
                  reject({
                    messages: [
                      "Sorry, something wen't wrong with that request.",
                    ],
                  });
                } else {
                  console.log(err.message);
                  reject({ messages: [err.message] });
                }
              });
          });
        },
        GetLast14DaysAnalytics() {
          return new Promise((resolve, reject) => {
            axios
              .get("/users/analytics/14days/")
              .then(({ data }) => {
                resolve(data);
              })
              .catch((err) => {
                console.log(err);
                if (err.response) {
                  reject(err.response.data);
                } else if (err.request) {
                  console.log(err.request);
                  reject({
                    messages: [
                      "Sorry, something wen't wrong with that request.",
                    ],
                  });
                } else {
                  console.log(err.message);
                  reject({ messages: [err.message] });
                }
              });
          });
        },
        RecoveryUserAssign(_,form){
          return new Promise((resolve, reject) => {
            if(form.key !== ""){
              axios
                .post("/users/recoveruserassign/",{key:form.key,role:form.role})
                .then((result)=>{
                  console.log(result);
                  resolve(result);
                })
                .catch((err)=>{
                  reject(err);
                })

            }

          });

        },
        RecoveryUserAssignDeny(_,form){
          return new Promise((resolve, reject) => {
            if(form.key !== ""){
              axios
                .post("/users/recoveruserassigndeny/",{key:form.key})
                .then((result)=>{
                  console.log(result);
                  resolve(result);
                })
                .catch((err)=>{
                  reject(err);
                })

            }

          });

        },
        KeyCheckDb(_,form){
          return new Promise((resolve,reject)=>{
            if(form.key !==""){
              axios
                .post("/users/keyidcheck/",{key:form.key})
                .then((result)=>{
                  resolve(result);
                  console.log(result);
                })
                .catch((err)=>{
                  reject(err);
                })
            }
          })
        }
      },
    },
    Tests: {
      namespaced: true,
      state: {},
      mutations: {},
      actions: {
        GetLatestTests({ rootState }) {
          return new Promise((resolve, reject) => {
            axios
              .post(
                "/tests/list/0/5/",
                { search: "", onlyuser: true },
                {
                  headers: {
                    Authorization: `Bearer ${
                      rootState.User.token || localStorage.MicronToken
                    }`,
                  },
                }
              )
              .then(({ data }) => {
                resolve(data);
              })
              .catch((err) => {
                if (err.response) {
                  reject(err.response.data);
                } else if (err.request) {
                  console.log(err.request);
                  reject({
                    messages: [
                      "Sorry, something wen't wrong with that request.",
                    ],
                  });
                } else {
                  console.log(err.message);
                  reject({ messages: [err.message] });
                }
              });
          });
        },
        GetTests({ rootState }, data) {
          return new Promise((resolve, reject) => {
            axios
              .post(
                `/tests/list/${data.offset}/${data.limit}/`,
                { search: data.search, onlyuser: data.onlyuser },
                {
                  headers: {
                    Authorization: `Bearer ${
                      rootState.User.token || localStorage.MicronToken
                    }`,
                  },
                }
              )
              .then(({ data }) => {
                resolve(data);
              })
              .catch((err) => {
                if (err.response) {
                  reject(err.response.data);
                } else if (err.request) {
                  console.log(err.request);
                  reject({
                    messages: [
                      "Sorry, something wen't wrong with that request.",
                    ],
                  });
                } else {
                  console.log(err.message);
                  reject({ messages: [err.message] });
                }
              });
          });
        },
        GetInfo({ rootState }, data) {
          return new Promise((resolve, reject) => {
            axios
              .get(`/tests/information/${data.id}`, {
                headers: {
                  Authorization: `Bearer ${
                    rootState.User.token || localStorage.MicronToken
                  }`,
                },
              })
              .then(({ data }) => {
                resolve(data);
              })
              .catch((err) => {
                if (err.response) {
                  reject(err.response.data);
                } else if (err.request) {
                  console.log(err.request);
                  reject({
                    messages: [
                      "Sorry, something wen't wrong with that request.",
                    ],
                  });
                } else {
                  console.log(err.message);
                  reject({ messages: [err.message] });
                }
              });
          });
        },       
        MultipartCreate({ rootState }, data) {
          return new Promise((resolve, reject) => {
            axios
              .get(`/tests/download/multipartcreate/${data.id}`, {
                headers: {
                  Authorization: `Bearer ${
                    rootState.User.token || localStorage.MicronToken
                  }`,
                },
              })
              .then(({ data }) => {
                resolve(data);
              })
              .catch((err) => {
                if (err.response) {
                  reject(err.response.data);
                } else if (err.request) {
                  console.log(err.request);
                  reject({
                    messages: [
                      "Sorry, something wen't wrong with that request.",
                    ],
                  });
                } else {
                  console.log(err.message);
                  reject({ messages: [err.message] });
                }
              });
          });
        },
        DownloadFECmulti({ rootState }, data) {
          return new Promise((resolve, reject) => {
            axios
              .get(`/tests/download/fecmulti/${data.id}`, {
                headers: {
                  Authorization: `Bearer ${
                    rootState.User.token || localStorage.MicronToken
                  }`,
                },
              })
              .then(({ data }) => {
                resolve(data);
              })
              .catch((err) => {
                if (err.response) {
                  reject(err.response.data);
                } else if (err.request) {
                  console.log(err.request);
                  reject({
                    messages: [
                      "Sorry, something wen't wrong with that request.",
                    ],
                  });
                } else {
                  console.log(err.message);
                  reject({ messages: [err.message] });
                }
              });
          });
        },
        DownloadFEC({ rootState }, data) {
          return new Promise((resolve, reject) => {
            axios
              .get(`/tests/download/fec/${data.id}`, {
                headers: {
                  Authorization: `Bearer ${
                    rootState.User.token || localStorage.MicronToken
                  }`,
                },
              })
              .then(({ data }) => {
                resolve(data);
              })
              .catch((err) => {
                if (err.response) {
                  reject(err.response.data);
                } else if (err.request) {
                  console.log(err.request);
                  reject({
                    messages: [
                      "Sorry, something wen't wrong with that request.",
                    ],
                  });
                } else {
                  console.log(err.message);
                  reject({ messages: [err.message] });
                }
              });
          });
        },
        DownloadMultiResults({rootState},tests)
        {
          return new Promise((resolve, reject) => {
            axios
              .post(
                "/tests/download/generatelist/",
                { tests: tests },
                {
                  headers: {
                    Authorization: `Bearer ${
                      rootState.User.token || localStorage.MicronToken
                    }`,
                  },
                }
              )
              .then(({ data }) => {
                resolve(data.data);
              })
              .catch((err) => {
                if (err.response) {
                  reject(err.response.data);
                } else if (err.request) {
                  console.log(err.request);
                  reject({
                    messages: [
                      "Sorry, something wen't wrong with that request.",
                    ],
                  });
                } else {
                  console.log(err.message);
                  reject({ messages: [err.message] });
                }
              });
          });
        },
        DownloadCombinedResults({rootState},tests)
        {
          return new Promise((resolve, reject) => {
            axios
              .post(
                "/tests/download/combinedresults/",
                { tests: tests },
                {
                  headers: {
                    Authorization: `Bearer ${
                      rootState.User.token || localStorage.MicronToken
                    }`,
                  },
                }
              )
              .then(({ data }) => {
                resolve(data);
              })
              .catch((err) => {
                if (err.response) {
                  reject(err.response.data);
                } else if (err.request) {
                  console.log(err.request);
                  reject({
                    messages: [
                      "Sorry, something wen't wrong with that request.",
                    ],
                  });
                } else {
                  console.log(err.message);
                  reject({ messages: [err.message] });
                }
              });
          });
        },
        DownloadMultiResultshistory({rootState},tests)
        {
          return new Promise((resolve, reject) => {
            axios
              .post(
                "/tests/download/generatelisthistory/",
                { from: tests.from,
                  to: tests.to
                 },
                {
                  headers: {
                    Authorization: `Bearer ${
                      rootState.User.token || localStorage.MicronToken
                    }`,
                  },
                }
              )
              .then(({ data }) => {
                resolve(data.data);
              })
              .catch((err) => {
                if (err.response) {
                  reject(err.response.data);
                } else if (err.request) {
                  console.log(err.request);
                  reject({
                    messages: [
                      "Sorry, something wen't wrong with that request.",
                    ],
                  });
                } else {
                  console.log(err.message);
                  reject({ messages: [err.message] });
                }
              });
          });
        },
        NotifyCompletion({ rootState }, data) {
          return new Promise((resolve, reject) => {
            axios            
              .post( "/tests/download/notifycompletion/", data, {
                headers: {
                  Authorization: `Bearer ${
                    rootState.User.token || localStorage.MicronToken
                  }`,
                },
              })                             
              .then(({ data }) => {
                resolve(data);
              })
              .catch((err) => {
                if (err.response) {
                  reject(err.response.data);
                } else if (err.request) {
                  console.log(err.request);
                  reject({
                    messages: [
                      "Sorry, something wen't wrong with that request.",
                    ],
                  });
                } else {
                  console.log(err.message);
                  reject({ messages: [err.message] });
                }
              });
          });         
        }
      },
    },
    Admin: {
      namespaced: true,
      state: {
        business_list: null,
        organisation_list: null,
      },
      mutations: {
        SET_BUSINESSLIST(state, data) {
          state.business_list = data;
        },
        SET_ORGANISATIONSLIST(state, data) {
          state.organisation_list = data;
        },
      },
      actions: {
        GetUsersDatatable({ rootState }, body) {
          return new Promise((resolve, reject) => {
            axios
              .post("/admin/users/list/", body, {
                headers: {
                  Authorization: `Bearer ${
                    rootState.User.token || localStorage.MicronToken
                  }`,
                },
              })
              .then(({ data }) => {
                resolve(data);
              })
              .catch((err) => {
                console.log(err);
                if (err.response) {
                  reject(err.response.data);
                } else if (err.request) {
                  console.log(err.request);
                  reject({
                    messages: [
                      "Sorry, something wen't wrong with that request.",
                    ],
                  });
                } else {
                  console.log(err.message);
                  reject({ messages: [err.message] });
                }
              });
          });
        },
        CreateNewUser({ rootState }, body) {
          return new Promise((resolve, reject) => {
            axios
              .post("/admin/users/create/", body, {
                headers: {
                  Authorization: `Bearer ${
                    rootState.User.token || localStorage.MicronToken
                  }`,
                },
              })
              .then(({ data }) => {
                console.log(data);
                resolve(data);
              })
              .catch((err) => {
                console.log(err);
                if (err.response) {
                  reject(err.response.data);
                } else if (err.request) {
                  console.log(err.request);
                  reject({
                    messages: [
                      err.message,
                      "Sorry, something wen't wrong with that request.",
                    ],
                  });
                } else {
                  console.log(err.message);
                  reject({ messages: [err.message] });
                }
              });
          });
        },
        GetUserInfo({ rootState }, id) {
          return new Promise((resolve, reject) => {
            axios
              .get(`/admin/users/${id}`, {
                headers: {
                  Authorization: `Bearer ${
                    rootState.User.token || localStorage.MicronToken
                  }`,
                },
              })
              .then(({ data }) => {
                resolve(data);
              })
              .catch((err) => {
                console.log(err);
                if (err.response) {
                  reject(err.response.data);
                } else if (err.request) {
                  console.log(err.request);
                  reject({
                    messages: [
                      "Sorry, something wen't wrong with that request.",
                    ],
                  });
                } else {
                  console.log(err.message);
                  reject({ messages: [err.message] });
                }
              });
          });
        },
        Getappversions({ rootState }) {
          return new Promise((resolve, reject) => {
            axios
              .get(`/admin/settings/appversion/`, {
                headers: {
                  Authorization: `Bearer ${
                    rootState.User.token || localStorage.MicronToken
                  }`,
                },
              })
              .then(({ data }) => {
                resolve(data);
              })
              .catch((err) => {
                console.log(err);
                if (err.response) {
                  reject(err.response.data);
                } else if (err.request) {
                  console.log(err.request);
                  reject({
                    messages: [
                      "Sorry, something wen't wrong with that request.",
                    ],
                  });
                } else {
                  console.log(err.message);
                  reject({ messages: [err.message] });
                }
              });
          });
        },

        Updateappversions({ rootState }, appversion) {
         
          return new Promise((resolve, reject) => {
            axios
              .post(`/admin/settings/appversion/`, appversion, {
                headers: {
                  Authorization: `Bearer ${
                    rootState.User.token || localStorage.MicronToken
                  }`,
                },
              })
              .then(({ data }) => {
                resolve(data);
              })
              .catch((err) => {
                console.log(err);
                if (err.response) {
                  reject(err.response.data);
                } else if (err.request) {
                  console.log(err.request);
                  reject({
                    messages: [
                      "Sorry, something wen't wrong with that request.",
                    ],
                  });
                } else {
                  console.log(err.message);
                  reject({ messages: [err.message] });
                }
              });
          });
        },

        UpdateForm(formdata) {
          return new Promise((resolve, reject) => {
            axios
              .post(`/tests/formcreate/`, formdata)
              .then(({ data }) => {
                resolve(data);
              })
              .catch((err) => {
                console.log(err);
                if (err.response) {
                  reject(err.response.data);
                } else if (err.request) {
                  console.log(err.request);
                  reject({
                    messages: [
                      "Sorry, something wen't wrong with that request.",
                    ],
                  });
                } else {
                  console.log(err.message);
                  reject({ messages: [err.message] });
                }
              });
          });
        },

        UpdateUserInfo({ rootState }, data) {
          return new Promise((resolve, reject) => {
            axios
              .post(`/admin/users/modify/${data.id}/`, data.body, {
                headers: {
                  Authorization: `Bearer ${
                    rootState.User.token || localStorage.MicronToken
                  }`,
                },
              })
              .then(({ data }) => {
                resolve(data);
              })
              .catch((err) => {
                console.log(err);
                if (err.response) {
                  reject(err.response.data);
                } else if (err.request) {
                  console.log(err.request);
                  reject({
                    messages: [
                      "Sorry, something wen't wrong with that request.",
                    ],
                  });
                } else {
                  console.log(err.message);
                  reject({ messages: [err.message] });
                }
              });
          });
        },
        GetTestsDatatable({ rootState }, body) {
          return new Promise((resolve, reject) => {
            axios
              .post("/admin/tests/list/", body, {
                headers: {
                  Authorization: `Bearer ${
                    rootState.User.token || localStorage.MicronToken
                  }`,
                },
              })
              .then(({ data }) => {
                resolve(data);
              })
              .catch((err) => {
                console.log(err);
                if (err.response) {
                  reject(err.response.data);
                } else if (err.request) {
                  console.log(err.request);
                  reject({
                    messages: [
                      "Sorry, something wen't wrong with that request.",
                    ],
                  });
                } else {
                  console.log(err.message);
                  reject({ messages: [err.message] });
                }
              });
          });
        },
        GetTestInfo({ rootState }, id) {
          return new Promise((resolve, reject) => {
            axios
              .get("/admin/tests/info/" + id, {
                headers: {
                  Authorization: `Bearer ${
                    rootState.User.token || localStorage.MicronToken
                  }`,
                },
              })
              .then(({ data }) => {
                resolve(data);
              })
              .catch((err) => {
                console.log(err);
                if (err.response) {
                  reject(err.response.data);
                } else if (err.request) {
                  console.log(err.request);
                  reject({
                    messages: [
                      "Sorry, something wen't wrong with that request.",
                    ],
                  });
                } else {
                  console.log(err.message);
                  reject({ messages: [err.message] });
                }
              });
          });
        },
        UpdateTestInfo({ rootState }, data) {
          return new Promise((resolve, reject) => {
            axios
              .post("/admin/tests/modify/" + data.id, data.body, {
                headers: {
                  Authorization: `Bearer ${
                    rootState.User.token || localStorage.MicronToken
                  }`,
                },
              })
              .then(({ data }) => {
                resolve(data);
              })
              .catch((err) => {
                console.log(err);
                if (err.response) {
                  reject(err.response.data);
                } else if (err.request) {
                  console.log(err.request);
                  reject({
                    messages: [
                      "Sorry, something wen't wrong with that request.",
                    ],
                  });
                } else {
                  console.log(err.message);
                  reject({ messages: [err.message] });
                }
              });
          });
        },
        CreateUpdateTestResult({ rootState }, data) {
          return new Promise((resolve, reject) => {
            axios
              .post("/admin/tests/modify/" + data.id + "/result/", data.body, {
                headers: {
                  Authorization: `Bearer ${
                    rootState.User.token || localStorage.MicronToken
                  }`,
                },
              })
              .then(({ data }) => {
                resolve(data);
              })
              .catch((err) => {
                console.log(err);
                if (err.response) {
                  reject(err.response.data);
                } else if (err.request) {
                  console.log(err.request);
                  reject({
                    messages: [
                      "Sorry, something wen't wrong with that request.",
                    ],
                  });
                } else {
                  console.log(err.message);
                  reject({ messages: [err.message] });
                }
              });
          });
        },
        DeleteTestResult({ rootState }, data) {
          return new Promise((resolve, reject) => {
            axios
              .delete(
                "/admin/tests/modify/" +
                  data.test_id +
                  "/remove/" +
                  data.result_id,
                {},
                {
                  headers: {
                    Authorization: `Bearer ${
                      rootState.User.token || localStorage.MicronToken
                    }`,
                  },
                }
              )
              .then(({ data }) => {
                resolve(data);
              })
              .catch((err) => {
                console.log(err);
                if (err.response) {
                  reject(err.response.data);
                } else if (err.request) {
                  console.log(err.request);
                  reject({
                    messages: [
                      "Sorry, something wen't wrong with that request.",
                    ],
                  });
                } else {
                  console.log(err.message);
                  reject({ messages: [err.message] });
                }
              });
          });
        },
        GetPolicies({ rootState }) {
          return new Promise((resolve, reject) => {
            axios
              .get("/admin/base/policies/", {
                headers: {
                  Authorization: `Bearer ${
                    rootState.User.token || localStorage.MicronToken
                  }`,
                },
              })
              .then(({ data }) => {
                resolve(data);
              })
              .catch((err) => {
                console.log(err);
                if (err.response) {
                  reject(err.response.data);
                } else if (err.request) {
                  console.log(err.request);
                  reject({
                    messages: [
                      "Sorry, something wen't wrong with that request.",
                    ],
                  });
                } else {
                  console.log(err.message);
                  reject({ messages: [err.message] });
                }
              });
          });
        },
        UpdatePolicies({ rootState }, body) {
          return new Promise((resolve, reject) => {
            axios
              .put(
                "/admin/base/policies/",
                { terms_of_use: body.Terms, privacy_policy: body.Privacy },
                {
                  headers: {
                    Authorization: `Bearer ${
                      rootState.User.token || localStorage.MicronToken
                    }`,
                  },
                }
              )
              .then(({ data }) => {
                resolve(data);
              })
              .catch((err) => {
                console.log(err);
                if (err.response) {
                  reject(err.response.data);
                } else if (err.request) {
                  console.log(err.request);
                  reject({
                    messages: [
                      "Sorry, something wen't wrong with that request.",
                    ],
                  });
                } else {
                  console.log(err.message);
                  reject({ messages: [err.message] });
                }
              });
          });
        },
        GetBusinessesDatatable({ rootState }, body) {
          return new Promise((resolve, reject) => {
            axios
              .post("/admin/businesses/list/", body, {
                headers: {
                  Authorization: `Bearer ${
                    rootState.User.token || localStorage.MicronToken
                  }`,
                },
              })
              .then(({ data }) => {
                resolve(data);
              })
              .catch((err) => {
                console.log(err);
                if (err.response) {
                  reject(err.response.data);
                } else if (err.request) {
                  console.log(err.request);
                  reject({
                    messages: [
                      "Sorry, something wen't wrong with that request.",
                    ],
                  });
                } else {
                  console.log(err.message);
                  reject({ messages: [err.message] });
                }
              });
          });
        },
        GetBusinessInfo({ rootState }, id) {
          return new Promise((resolve, reject) => {
            axios
              .get(`/admin/businesses/info/${id}/`, {
                headers: {
                  Authorization: `Bearer ${
                    rootState.User.token || localStorage.MicronToken
                  }`,
                },
              })
              .then(({ data }) => {
                // console.log("Here's the data from admin business info ---------------",data)
                resolve(data);
              })
              .catch((err) => {
                console.log(err);
                if (err.response) {
                  reject(err.response.data);
                } else if (err.request) {
                  console.log(err.request);
                  reject({
                    messages: [
                      "Sorry, something wen't wrong with that request.",
                    ],
                  });
                } else {
                  console.log(err.message);
                  reject({ messages: [err.message] });
                }
              });
          });
        },
        GetBusinessDetailedReport({ rootState }, data) {
          return new Promise((resolve, reject) => {
            axios
              .get(`/admin/businesses/${data.id}/detailedreport/`, {
                headers: {
                  Authorization: `Bearer ${
                    rootState.User.token || localStorage.MicronToken
                  }`,
                },
              })
              .then(({ data }) => {
                resolve(data);
              })
              .catch((err) => {
                console.log(err);
                if (err.response) {
                  reject(err.response.data);
                } else if (err.request) {
                  console.log(err.request);
                  reject({
                    messages: [
                      "Sorry, something wen't wrong with that request.",
                    ],
                  });
                } else {
                  console.log(err.message);
                  reject({ messages: [err.message] });
                }
              });
          });
        },
        UpdateBusinessInfo({ rootState }, data) {
          return new Promise((resolve, reject) => {
            var formData = new FormData();
            var credits = data.credits;
            if (data.change_logo) {
              formData.append("logo", data.logo, "logo.png");
              formData.append("address", data.address);
              formData.append("postcode", data.postcode);
              formData.append("contact_email", data.contact_email);
              formData.append("contact_telephone", data.contact_telephone);
              formData.append("name", data.name);
              formData.append("organisation", data.organisationid);
              formData.append("infinite_credits", data.infinite_credits);
              formData.append("mkit", data.mkit);
              formData.append("fluke_kit", data.fluke_kit);
              if (data.fluke_kit === "true") {
                formData.append("flukekitnumber", data.flukekitnumber);
              }
              formData.append("country", data.country);
              if (data.infinite_credits === "true") {
                formData.append("activationdate", data.activationdate);
                formData.append("expirydate", data.expirydate);
                credits = 0;
              }
              formData.append("credits", credits);
            } else {
              formData.append("address", data.address);
              formData.append("postcode", data.postcode);
              formData.append("contact_email", data.contact_email);
              formData.append("contact_telephone", data.contact_telephone);
              formData.append("name", data.name);
              formData.append("organisation", data.organisationid);
              formData.append("infinite_credits", data.infinite_credits);
              formData.append("mkit", data.mkit);
              formData.append("fluke_kit", data.fluke_kit);
              if (data.fluke_kit === "true") {
                formData.append("flukekitnumber", data.flukekitnumber);
              }
              formData.append("country", data.country);
              if (data.infinite_credits === "true") {
                formData.append("activationdate", data.activationdate);
                formData.append("expirydate", data.expirydate);
                credits = 0;
              }              
              formData.append("credits", credits);
            }
            axios
              .put("/admin/businesses/info/" + data.id + "/", formData, {
                headers: {
                  Authorization: `Bearer ${
                    rootState.User.token || localStorage.MicronToken
                  }`,
                  "Content-Type": "multipart/form-data",
                },
              })
              .then(({ data }) => {
                resolve(data);
              })
              .catch((err) => {
                if (err.response) {
                  reject(err.response.data);
                } else if (err.request) {
                  console.log(err.request);
                  reject({
                    messages: [
                      "Sorry, something wen't wrong with that request.",
                    ],
                  });
                } else {
                  console.log(err.message);
                  reject({ messages: [err.message] });
                }
              });
          });
        },
        CreateBusiness({ rootState }, data) {
          return new Promise((resolve, reject) => {
            axios
              .post("/admin/businesses/create/", data, {
                headers: {
                  Authorization: `Bearer ${
                    rootState.User.token || localStorage.MicronToken
                  }`,
                },
              })
              .then(({ data }) => {
                resolve(data);
              })
              .catch((err) => {
                if (err.response) {
                  reject(err.response.data);
                } else if (err.request) {
                  console.log(err.request);
                  reject({
                    messages: [
                      "Sorry, something wen't wrong with that request.",
                    ],
                  });
                } else {
                  console.log(err.message);
                  reject({ messages: [err.message] });
                }
              });
          });
        },
        AddUserToBusiness({ rootState }, data) {
          return new Promise((resolve, reject) => {
            axios
              .post("/admin/businesses/info/users/" + data.id + "/", data, {
                headers: {
                  Authorization: `Bearer ${
                    rootState.User.token || localStorage.MicronToken
                  }`,
                },
              })
              .then(({ data }) => {
                resolve(data);
              })
              .catch((err) => {
                if (err.response) {
                  reject(err.response.data);
                } else if (err.request) {
                  console.log(err.request);
                  reject({
                    messages: [
                      "Sorry, something wen't wrong with that request.",
                    ],
                  });
                } else {
                  console.log(err.message);
                  reject({ messages: [err.message] });
                }
              });
          });
        },
        RemoveUserFromBusiness({ rootState }, data) {
          return new Promise((resolve, reject) => {
            axios
              .put(
                "/admin/businesses/info/users/remove/" + data.id + "/",
                data,
                {
                  headers: {
                    Authorization: `Bearer ${
                      rootState.User.token || localStorage.MicronToken
                    }`,
                  },
                }
              )
              .then(({ data }) => {
                resolve(data);
              })
              .catch((err) => {
                if (err.response) {
                  reject(err.response.data);
                } else if (err.request) {
                  console.log(err.request);
                  reject({
                    messages: [
                      "Sorry, something wen't wrong with that request.",
                    ],
                  });
                } else {
                  console.log(err.message);
                  reject({ messages: [err.message] });
                }
              });
          });
        },
        GetListOfBusinesses({ rootState, state, commit }) {
          return new Promise((resolve, reject) => {
            if (state.business_list == null) {
              axios
                .get("/admin/businesses/listall/", {
                  headers: {
                    Authorization: `Bearer ${
                      rootState.User.token || localStorage.MicronToken
                    }`,
                  },
                })
                .then(({ data }) => {
                  commit("SET_BUSINESSLIST", data.data);
                  resolve(data.data);
                })
                .catch((err) => {
                  if (err.response) {
                    reject(err.response.data);
                  } else if (err.request) {
                    console.log(err.request);
                    reject({
                      messages: [
                        "Sorry, something wen't wrong with that request.",
                      ],
                    });
                  } else {
                    console.log(err.message);
                    reject({ messages: [err.message] });
                  }
                });
            } else {
              resolve(state.business_list);
            }
          });
        },
        DownloadTestsData({ rootState }, tests) {
          return new Promise((resolve, reject) => {
            axios
              .post(
                "/admin/tests/generatelist/",
                { tests: tests },
                {
                  headers: {
                    Authorization: `Bearer ${
                      rootState.User.token || localStorage.MicronToken
                    }`,
                  },
                }
              )
              .then(({ data }) => {
                resolve(data.data);
              })
              .catch((err) => {
                if (err.response) {
                  reject(err.response.data);
                } else if (err.request) {
                  console.log(err.request);
                  reject({
                    messages: [
                      "Sorry, something wen't wrong with that request.",
                    ],
                  });
                } else {
                  console.log(err.message);
                  reject({ messages: [err.message] });
                }
              });
          });
        },
        CreateOrganisation({ rootState }, data) {
          return new Promise((resolve, reject) => {
            axios
              .post("/admin/organisations/create/", data, {
                headers: {
                  Authorization: `Bearer ${
                    rootState.User.token || localStorage.MicronToken
                  }`,
                },
              })
              .then(({ data }) => {
                resolve(data);
              })
              .catch((err) => {
                if (err.response) {
                  reject(err.response.data);
                } else if (err.request) {
                  console.log(err.request);
                  reject({
                    messages: [
                      "Sorry, something wen't wrong with that request.",
                    ],
                  });
                } else {
                  console.log(err.message);
                  reject({ messages: [err.message] });
                }
              });
          });
        },
        GetOrganisationsDatatable({ rootState }, body) {
          return new Promise((resolve, reject) => {
            axios
              .post("/admin/organisations/list/", body, {
                headers: {
                  Authorization: `Bearer ${
                    rootState.User.token || localStorage.MicronToken
                  }`,
                },
              })
              .then(({ data }) => {
                resolve(data);
              })
              .catch((err) => {
                console.log(err);
                if (err.response) {
                  reject(err.response.data);
                } else if (err.request) {
                  console.log(err.request);
                  reject({
                    messages: [
                      "Sorry, something wen't wrong with that request.",
                    ],
                  });
                } else {
                  console.log(err.message);
                  reject({ messages: [err.message] });
                }
              });
          });
        },
        GetOrganisationInfo({ rootState }, id) {
          return new Promise((resolve, reject) => {
            axios
              .get(`/admin/organisations/info/${id}/`, {
                headers: {
                  Authorization: `Bearer ${
                    rootState.User.token || localStorage.MicronToken
                  }`,
                },
              })
              .then(({ data }) => {
                resolve(data);
              })
              .catch((err) => {
                console.log(err);
                if (err.response) {
                  reject(err.response.data);
                } else if (err.request) {
                  console.log(err.request);
                  reject({
                    messages: [
                      "Sorry, something wen't wrong with that request.",
                    ],
                  });
                } else {
                  console.log(err.message);
                  reject({ messages: [err.message] });
                }
              });
          });
        },
        UpdateOrganisationInfo({ rootState }, data) {
          return new Promise((resolve, reject) => {
            axios
              .put("/admin/organisations/info/" + data.id + "/", data, {
                headers: {
                  Authorization: `Bearer ${
                    rootState.User.token || localStorage.MicronToken
                  }`,
                },
              })
              .then(({ data }) => {
                resolve(data);
              })
              .catch((err) => {
                if (err.response) {
                  reject(err.response.data);
                } else if (err.request) {
                  console.log(err.request);
                  reject({
                    messages: [
                      "Sorry, something wen't wrong with that request.",
                    ],
                  });
                } else {
                  console.log(err.message);
                  reject({ messages: [err.message] });
                }
              });
          });
        },
        GetListOfOrganisations({ rootState, state, commit }) {
          return new Promise((resolve, reject) => {
            if (state.organisation_list == null) {
              axios
                .get("/admin/organisations/listall/", {
                  headers: {
                    Authorization: `Bearer ${
                      rootState.User.token || localStorage.MicronToken
                    }`,
                  },
                })
                .then(({ data }) => {
                  commit("SET_ORGANISATIONSLIST", data.data);
                  resolve(data.data);
                })
                .catch((err) => {
                  if (err.response) {
                    reject(err.response.data);
                  } else if (err.request) {
                    console.log(err.request);
                    reject({
                      messages: [
                        "Sorry, something wen't wrong with that request.",
                      ],
                    });
                  } else {
                    console.log(err.message);
                    reject({ messages: [err.message] });
                  }
                });
            } else {
              resolve(state.organisation_list);
            }
          });
        },
        AddUserToOrganisation({ rootState }, data) {
          return new Promise((resolve, reject) => {
            axios
              .post("/admin/organisations/info/users/" + data.id + "/", data, {
                headers: {
                  Authorization: `Bearer ${
                    rootState.User.token || localStorage.MicronToken
                  }`,
                },
              })
              .then(({ data }) => {
                resolve(data);
              })
              .catch((err) => {
                if (err.response) {
                  reject(err.response.data);
                } else if (err.request) {
                  console.log(err.request);
                  reject({
                    messages: [
                      "Sorry, something wen't wrong with that request.",
                    ],
                  });
                } else {
                  console.log(err.message);
                  reject({ messages: [err.message] });
                }
              });
          });
        },
        RemoveUserFromOrganisation({ rootState }, data) {
          return new Promise((resolve, reject) => {
            axios
              .put(
                "/admin/organisations/info/users/remove/" + data.id + "/",
                data,
                {
                  headers: {
                    Authorization: `Bearer ${
                      rootState.User.token || localStorage.MicronToken
                    }`,
                  },
                }
              )
              .then(({ data }) => {
                resolve(data);
              })
              .catch((err) => {
                if (err.response) {
                  reject(err.response.data);
                } else if (err.request) {
                  console.log(err.request);
                  reject({
                    messages: [
                      "Sorry, something wen't wrong with that request.",
                    ],
                  });
                } else {
                  console.log(err.message);
                  reject({ messages: [err.message] });
                }
              });
          });
        },
        GetBusienssCreditsDatatable({ rootState }, data) {
          return new Promise((resolve, reject) => {
            axios
              .post("/admin/finance/list/", data, {
                headers: {
                  Authorization: `Bearer ${
                    rootState.User.token || localStorage.MicronToken
                  }`,
                },
              })
              .then(({ data }) => {
                resolve(data);
              })
              .catch((err) => {
                if (err.response) {
                  reject(err.response.data);
                } else if (err.request) {
                  console.log(err.request);
                  reject({
                    messages: [
                      "Sorry, something wen't wrong with that request.",
                    ],
                  });
                } else {
                  console.log(err.message);
                  reject({ messages: [err.message] });
                }
              });
          });
        },
        AddCommentToTest({ rootState }, data) {
          return new Promise((resolve, reject) => {
            axios
              .post(`/admin/tests/addcomment/${data.test_id}/`, data, {
                headers: {
                  Authorization: `Bearer ${
                    rootState.User.token || localStorage.MicronToken
                  }`,
                },
              })
              .then(({ data }) => {
                resolve(data);
              })
              .catch((err) => {
                if (err.response) {
                  reject(err.response.data);
                } else if (err.request) {
                  console.log(err.request);
                  reject({
                    messages: [
                      "Sorry, something wen't wrong with that request.",
                    ],
                  });
                } else {
                  console.log(err.message);
                  reject({ messages: [err.message] });
                }
              });
          });
        },
        MultipartCreate({ rootState }, data) {
          return new Promise((resolve, reject) => {
            axios
              .get(`/admin/download/multipartcreate/${data}`, {
                headers: {
                  Authorization: `Bearer ${
                    rootState.User.token || localStorage.MicronToken
                  }`,
                },
              })
              .then(({ data }) => {
                resolve(data);
              })
              .catch((err) => {
                if (err.response) {
                  reject(err.response.data);
                } else if (err.request) {
                  console.log(err.request);
                  reject({
                    messages: [
                      "Sorry, something wen't wrong with that request.",
                    ],
                  });
                } else {
                  console.log(err.message);
                  reject({ messages: [err.message] });
                }
              });
          });
        }
        ,        
        DownloadFECmulti({ rootState }, data) {
          return new Promise((resolve, reject) => {
            axios
              .get(`/admin/download/fecmulti/${data.id}`, {
                headers: {
                  Authorization: `Bearer ${
                    rootState.User.token || localStorage.MicronToken
                  }`,
                },
              })
              .then(({ data }) => {
                resolve(data);
              })
              .catch((err) => {
                if (err.response) {
                  reject(err.response.data);
                } else if (err.request) {
                  console.log(err.request);
                  reject({
                    messages: [
                      "Sorry, something wen't wrong with that request.",
                    ],
                  });
                } else {
                  console.log(err.message);
                  reject({ messages: [err.message] });
                }
              });
          });
        },
        DownloadFEC({ rootState }, data) {
          return new Promise((resolve, reject) => {
            axios
              .get(`/admin/download/fec/${data.id}/`, {
                headers: {
                  Authorization: `Bearer ${
                    rootState.User.token || localStorage.MicronToken
                  }`,
                },
              })
              .then(({ data }) => {
                resolve(data);
              })
              .catch((err) => {
                if (err.response) {
                  reject(err.response.data);
                } else if (err.request) {
                  console.log(err.request);
                  reject({
                    messages: [
                      "Sorry, something wen't wrong with that request.",
                    ],
                  });
                } else {
                  console.log(err.message);
                  reject({ messages: [err.message] });
                }
              });
          });
        },
        GetTotalTestsAnalytics({ rootState }, data) {
          return new Promise((resolve, reject) => {
            axios
              .post(`/admin/analytics/tests/`, data, {
                headers: {
                  Authorization: `Bearer ${
                    rootState.User.token || localStorage.MicronToken
                  }`,
                },
              })
              .then(({ data }) => {
                resolve(data);
              })
              .catch((err) => {
                if (err.response) {
                  reject(err.response.data);
                } else if (err.request) {
                  console.log(err.request);
                  reject({
                    messages: [
                      "Sorry, something wen't wrong with that request.",
                    ],
                  });
                } else {
                  console.log(err.message);
                  reject({ messages: [err.message] });
                }
              });
          });
        },
        GetTotalTestsByWeekAnalytics({ rootState }, data) {
          return new Promise((resolve, reject) => {
            axios
              .post(`/admin/analytics/testsbyweek/`, data, {
                headers: {
                  Authorization: `Bearer ${
                    rootState.User.token || localStorage.MicronToken
                  }`,
                },
              })
              .then(({ data }) => {
                resolve(data);
              })
              .catch((err) => {
                if (err.response) {
                  reject(err.response.data);
                } else if (err.request) {
                  console.log(err.request);
                  reject({
                    messages: [
                      "Sorry, something wen't wrong with that request.",
                    ],
                  });
                } else {
                  console.log(err.message);
                  reject({ messages: [err.message] });
                }
              });
          });
        },
        GetTotalTestsByBusinessAnalytics({ rootState }, data) {
          return new Promise((resolve, reject) => {
            axios
              .post(`/admin/analytics/testsbybusiness/`, data, {
                headers: {
                  Authorization: `Bearer ${
                    rootState.User.token || localStorage.MicronToken
                  }`,
                },
              })
              .then(({ data }) => {
                resolve(data);
              })
              .catch((err) => {
                if (err.response) {
                  reject(err.response.data);
                } else if (err.request) {
                  console.log(err.request);
                  reject({
                    messages: [
                      "Sorry, something wen't wrong with that request.",
                    ],
                  });
                } else {
                  console.log(err.message);
                  reject({ messages: [err.message] });
                }
              });
          });
        },
        GetTotalsBlocks({ rootState }) {
          return new Promise((resolve, reject) => {
            axios
              .get(`/admin/analytics/totals/`, {
                headers: {
                  Authorization: `Bearer ${
                    rootState.User.token || localStorage.MicronToken
                  }`,
                },
              })
              .then(({ data }) => {
                resolve(data);
              })
              .catch((err) => {
                if (err.response) {
                  reject(err.response.data);
                } else if (err.request) {
                  console.log(err.request);
                  reject({
                    messages: [
                      "Sorry, something wen't wrong with that request.",
                    ],
                  });
                } else {
                  console.log(err.message);
                  reject({ messages: [err.message] });
                }
              });
          });
        },
        GetBulkResult({ rootState }) {
          return new Promise((resolve, reject) => {
            axios
              .get(`/admin/analytics/resultbulk/`, {
                headers: {
                  Authorization: `Bearer ${
                    rootState.User.token || localStorage.MicronToken
                  }`,
                },
              })
              .then(({ data }) => {
                resolve(data.data);
              })
              .catch((err) => {
                if (err.response) {
                  reject(err.response.data);
                } else if (err.request) {
                  console.log(err.request);
                  reject({
                    messages: [
                      "Sorry, something wen't wrong with that request.",
                    ],
                  });
                } else {
                  console.log(err.message);
                  reject({ messages: [err.message] });
                }
              });
          });
        },
        GetDetailedReportAnalytics({ rootState }, data) {
          return new Promise((resolve, reject) => {
            axios
              .post(`/admin/analytics/detailedreport/`, data, {
                headers: {
                  Authorization: `Bearer ${
                    rootState.User.token || localStorage.MicronToken
                  }`,
                },
              })
              .then(({ data }) => {
                resolve(data);
              })
              .catch((err) => {
                if (err.response) {
                  reject(err.response.data);
                } else if (err.request) {
                  console.log(err.request);
                  reject({
                    messages: [
                      "Sorry, something wen't wrong with that request.",
                    ],
                  });
                } else {
                  console.log(err.message);
                  reject({ messages: [err.message] });
                }
              });
          });
        },
        GetShopSalesDatatable({ rootState }, data) {
          return new Promise((resolve, reject) => {
            axios
              .post("/admin/shop/sales/list/", data, {
                headers: {
                  Authorization: `Bearer ${
                    rootState.User.token || localStorage.MicronToken
                  }`,
                },
              })
              .then(({ data }) => {
                resolve(data);
              })
              .catch((err) => {
                if (err.response) {
                  reject(err.response.data);
                } else if (err.request) {
                  console.log(err.request);
                  reject({
                    messages: [
                      "Sorry, something wen't wrong with that request.",
                    ],
                  });
                } else {
                  console.log(err.message);
                  reject({ messages: [err.message] });
                }
              });
          });
        },
        GetShopProductsDatatable({ rootState }, data) {
          return new Promise((resolve, reject) => {
            axios
              .post("/admin/shop/products/list/", data, {
                headers: {
                  Authorization: `Bearer ${
                    rootState.User.token || localStorage.MicronToken
                  }`,
                },
              })
              .then(({ data }) => {
                resolve(data);
              })
              .catch((err) => {
                if (err.response) {
                  reject(err.response.data);
                } else if (err.request) {
                  console.log(err.request);
                  reject({
                    messages: [
                      "Sorry, something wen't wrong with that request.",
                    ],
                  });
                } else {
                  console.log(err.message);
                  reject({ messages: [err.message] });
                }
              });
          });
        },
        UpdateShopSaleStatus({ rootState }, data) {
          return new Promise((resolve, reject) => {
            axios
              .put(`/admin/shop/sales/order/${data.id}/status/`, data, {
                headers: {
                  Authorization: `Bearer ${
                    rootState.User.token || localStorage.MicronToken
                  }`,
                },
              })
              .then(({ data }) => {
                resolve(data);
              })
              .catch((err) => {
                if (err.response) {
                  reject(err.response.data);
                } else if (err.request) {
                  console.log(err.request);
                  reject({
                    messages: [
                      "Sorry, something wen't wrong with that request.",
                    ],
                  });
                } else {
                  console.log(err.message);
                  reject({ messages: [err.message] });
                }
              });
          });
        },
        CreateShopProduct({ rootState }, data) {
          return new Promise((resolve, reject) => {
            var formData = new FormData();
            if (data.image) {
              formData.append("image", data.image, "image.png");
              formData.append("title", data.title);
              formData.append("description", data.description);
              formData.append("nett", data.nett);
              formData.append("gross", data.gross);
              formData.append("min_quantity", data.min_quantity);
              formData.append("max_quantity", data.max_quantity);
              formData.append("status", data.status);
            } else {
              formData.append("title", data.title);
              formData.append("description", data.description);
              formData.append("nett", data.nett);
              formData.append("gross", data.gross);
              formData.append("min_quantity", data.min_quantity);
              formData.append("max_quantity", data.max_quantity);
              formData.append("status", data.status);
            }
            axios
              .post("/admin/shop/products/create/", formData, {
                headers: {
                  Authorization: `Bearer ${
                    rootState.User.token || localStorage.MicronToken
                  }`,
                  "Content-Type": "multipart/form-data",
                },
              })
              .then(({ data }) => {
                resolve(data);
              })
              .catch((err) => {
                if (err.response) {
                  reject(err.response.data);
                } else if (err.request) {
                  console.log(err.request);
                  reject({
                    messages: [
                      "Sorry, something wen't wrong with that request.",
                    ],
                  });
                } else {
                  console.log(err.message);
                  reject({ messages: [err.message] });
                }
              });
          });
        },
        UpdateShopProduct({ rootState }, data) {
          return new Promise((resolve, reject) => {
            var formData = new FormData();
            if (data.image instanceof Blob) {
              formData.append("image", data.image, "image.png");
              formData.append("title", data.title);
              formData.append("description", data.description);
              formData.append("nett", data.nett);
              formData.append("gross", data.gross);
              formData.append("min_quantity", data.min_quantity);
              formData.append("max_quantity", data.max_quantity);
              formData.append("status", data.status);
            } else {
              formData.append("title", data.title);
              formData.append("description", data.description);
              formData.append("nett", data.nett);
              formData.append("gross", data.gross);
              formData.append("min_quantity", data.min_quantity);
              formData.append("max_quantity", data.max_quantity);
              formData.append("status", data.status);
            }
            axios
              .put(`/admin/shop/products/update/${data.id}/`, formData, {
                headers: {
                  Authorization: `Bearer ${
                    rootState.User.token || localStorage.MicronToken
                  }`,
                  "Content-Type": "multipart/form-data",
                },
              })
              .then(({ data }) => {
                resolve(data);
              })
              .catch((err) => {
                if (err.response) {
                  reject(err.response.data);
                } else if (err.request) {
                  console.log(err.request);
                  reject({
                    messages: [
                      "Sorry, something wen't wrong with that request.",
                    ],
                  });
                } else {
                  console.log(err.message);
                  reject({ messages: [err.message] });
                }
              });
          });
        },
        GetShopProduct({ rootState }, id) {
          return new Promise((resolve, reject) => {
            axios
              .get(`/admin/shop/products/${id}/`, {
                headers: {
                  Authorization: `Bearer ${
                    rootState.User.token || localStorage.MicronToken
                  }`,
                },
              })
              .then(({ data }) => {
                resolve(data);
              })
              .catch((err) => {
                if (err.response) {
                  reject(err.response.data);
                } else if (err.request) {
                  console.log(err.request);
                  reject({
                    messages: [
                      "Sorry, something wen't wrong with that request.",
                    ],
                  });
                } else {
                  console.log(err.message);
                  reject({ messages: [err.message] });
                }
              });
          });
        },
        LookupAddressByPostcode({ rootState }, { postcode, country }) {
          return new Promise((resolve, reject) => {
            axios
              .post(
                "/admin/address/lookup",
                { postcode, country },
                {
                  headers: {
                    Authorization: `Bearer ${
                      rootState.User.token || localStorage.MicronToken
                    }`,
                  },
                }
              )
              .then(({ data }) => {
                resolve(data);
              })
              .catch((err) => {
                if (err.response) {
                  reject(err.response.data);
                } else if (err.request) {
                  console.log(err.request);
                  reject({
                    messages: [
                      "Sorry, something went wrong with that request.",
                    ],
                  });
                } else {
                  console.log(err.message);
                  reject({ messages: [err.message] });
                }
              });
          });
        },
        AutocompleteAddress({ rootState }, { input, country }) {
          return new Promise((resolve, reject) => {
            axios
              .post(
                "/admin/address/autocomplete",
                { input, country },
                {
                  headers: {
                    Authorization: `Bearer ${
                      rootState.User.token || localStorage.MicronToken
                    }`,
                  },
                }
              )
              .then(({ data }) => {
                resolve(data);
              })
              .catch((err) => {
                if (err.response) {
                  reject(err.response.data);
                } else if (err.request) {
                  console.log(err.request);
                  reject({
                    messages: [
                      "Sorry, something went wrong with that request.",
                    ],
                  });
                } else {
                  console.log(err.message);
                  reject({ messages: [err.message] });
                }
              });
          });
        },
        RetrieveFullAddress({ rootState }, { id, input, country }) {
          return new Promise((resolve, reject) => {
            axios
              .post(
                "/admin/address/retrieve",
                { id, input, country },
                {
                  headers: {
                    Authorization: `Bearer ${
                      rootState.User.token || localStorage.MicronToken
                    }`,
                  },
                }
              )
              .then(({ data }) => {
                resolve(data);
              })
              .catch((err) => {
                if (err.response) {
                  reject(err.response.data);
                } else if (err.request) {
                  console.log(err.request);
                  reject({
                    messages: [
                      "Sorry, something went wrong with that request.",
                    ],
                  });
                } else {
                  console.log(err.message);
                  reject({ messages: [err.message] });
                }
              });
          });
        },        
        GetTestsnumber({ rootState }, body) {
          return new Promise((resolve, reject) => {
          
            axios
              .post("/tests/testnumber/", body, {
                headers: {
                  Authorization: `Bearer ${
                    rootState.User.token || localStorage.MicronToken
                  }`,
                },
              })
              .then(({ data }) => {
                resolve(data);
              })
              .catch((err) => {
                console.log(err);
                if (err.response) {
                  reject(err.response.data);
                } else if (err.request) {
                  console.log(err.request);
                  reject({
                    messages: [
                      "Sorry, something wen't wrong with that request.",
                    ],
                  });
                } else {
                  console.log(err.message);
                  reject({ messages: [err.message] });
                }
              });
          });
        },
      },
    },
    Business: {
      namespaced: true,
      state: {},
      actions: {
        GetBusinessInfo({ rootState }, data) {
          return new Promise((resolve, reject) => {
            axios
              .get("/businesses/info/" + data.id + "/", {
                headers: {
                  Authorization: `Bearer ${
                    rootState.User.token || localStorage.MicronToken
                  }`,
                },
              })
              .then(({ data }) => {
                resolve(data);
              })
              .catch((err) => {
                if (err.response) {
                  reject(err.response.data);
                } else if (err.request) {
                  console.log(err.request);
                  reject({
                    messages: [
                      "Sorry, something wen't wrong with that request.",
                    ],
                  });
                } else {
                  console.log(err.message);
                  reject({ messages: [err.message] });
                }
              });
          });
        },
        GetBusinessUsers({ rootState }, data) {
          return new Promise((resolve, reject) => {
            axios
              .get("/businesses/users/" + data.id + "/", {
                headers: {
                  Authorization: `Bearer ${
                    rootState.User.token || localStorage.MicronToken
                  }`,
                },
              })
              .then(({ data }) => {
                resolve(data);
              })
              .catch((err) => {
                if (err.response) {
                  reject(err.response.data);
                } else if (err.request) {
                  console.log(err.request);
                  reject({
                    messages: [
                      "Sorry, something wen't wrong with that request.",
                    ],
                  });
                } else {
                  console.log(err.message);
                  reject({ messages: [err.message] });
                }
              });
          });
        },
        InviteBusinessUser({ rootState }, data) {
          return new Promise((resolve, reject) => {
            axios
              .post("/businesses/users/invite/" + data.id + "/", data, {
                headers: {
                  Authorization: `Bearer ${
                    rootState.User.token || localStorage.MicronToken
                  }`,
                },
              })
              .then(({ data }) => {
                resolve(data);
              })
              .catch((err) => {
                if (err.response) {
                  reject(err.response.data);
                } else if (err.request) {
                  console.log(err.request);
                  reject({
                    messages: [
                      "Sorry, something wen't wrong with that request.",
                    ],
                  });
                } else {
                  console.log(err.message);
                  reject({ messages: [err.message] });
                }
              });
          });
        },
        UpdateBusinessInfo({ rootState }, data) {
          return new Promise((resolve, reject) => {
            var formData = new FormData();
            if (data.change_logo !== false) {
              formData.append("logo", data.logo, "logo.png");
              formData.append("address", data.address);
              formData.append("postcode", data.postcode);
              formData.append("contact_email", data.contact_email);
              formData.append("contact_telephone", data.contact_telephone);
            } else {
              formData.append("address", data.address);
              formData.append("postcode", data.postcode);
              formData.append("contact_email", data.contact_email);
              formData.append("contact_telephone", data.contact_telephone);
            }
            console.log(formData);
            axios
              .put("/businesses/info/" + data.id + "/", formData, {
                headers: {
                  Authorization: `Bearer ${
                    rootState.User.token || localStorage.MicronToken
                  }`,
                  "Content-Type": "multipart/form-data",
                },
              })
              .then(({ data }) => {
                resolve(data);
              })
              .catch((err) => {
                if (err.response) {
                  reject(err.response.data);
                } else if (err.request) {
                  console.log(err.request);
                  reject({
                    messages: [
                      "Sorry, something wen't wrong with that request.",
                    ],
                  });
                } else {
                  console.log(err.message);
                  reject({ messages: [err.message] });
                }
              });
          });
        },
        RemoveUserFromBusiness({ rootState }, data) {
          return new Promise((resolve, reject) => {
            axios
              .put("/businesses/users/remove/" + data.id + "/", data, {
                headers: {
                  Authorization: `Bearer ${
                    rootState.User.token || localStorage.MicronToken
                  }`,
                },
              })
              .then(({ data }) => {
                resolve(data);
              })
              .catch((err) => {
                if (err.response) {
                  reject(err.response.data);
                } else if (err.request) {
                  console.log(err.request);
                  reject({
                    messages: [
                      "Sorry, something wen't wrong with that request.",
                    ],
                  });
                } else {
                  console.log(err.message);
                  reject({ messages: [err.message] });
                }
              });
          });
        },
      },
    },
    Organisation: {
      namespaced: true,
      state: {},
      actions: {
        GetBusinessesDatatable({ rootState }, body) {
          return new Promise((resolve, reject) => {
            axios
              .post("/organisations/businesses/list/", body, {
                headers: {
                  Authorization: `Bearer ${
                    rootState.User.token || localStorage.MicronToken
                  }`,
                },
              })
              .then(({ data }) => {
                resolve(data);
              })
              .catch((err) => {
                console.log(err);
                if (err.response) {
                  reject(err.response.data);
                } else if (err.request) {
                  console.log(err.request);
                  reject({
                    messages: [
                      "Sorry, something wen't wrong with that request.",
                    ],
                  });
                } else {
                  console.log(err.message);
                  reject({ messages: [err.message] });
                }
              });
          });
        },
        GetBusinessCreditReceiptsDatatable({ rootState }, body) {
          return new Promise((resolve, reject) => {
            axios
              .post(
                `/organisations/${body.organisation}/creditreceipts/`,
                body,
                {
                  headers: {
                    Authorization: `Bearer ${
                      rootState.User.token || localStorage.MicronToken
                    }`,
                  },
                }
              )
              .then(({ data }) => {
                resolve(data);
              })
              .catch((err) => {
                console.log(err);
                if (err.response) {
                  reject(err.response.data);
                } else if (err.request) {
                  console.log(err.request);
                  reject({
                    messages: [
                      "Sorry, something wen't wrong with that request.",
                    ],
                  });
                } else {
                  console.log(err.message);
                  reject({ messages: [err.message] });
                }
              });
          });
        },
        GetOrganisationInfo({ rootState }, data) {
          return new Promise((resolve, reject) => {
            axios
              .get("/organisations/info/" + data.id + "/", {
                headers: {
                  Authorization: `Bearer ${
                    rootState.User.token || localStorage.MicronToken
                  }`,
                },
              })
              .then(({ data }) => {
                resolve(data);
              })
              .catch((err) => {
                if (err.response) {
                  reject(err.response.data);
                } else if (err.request) {
                  console.log(err.request);
                  reject({
                    messages: [
                      "Sorry, something wen't wrong with that request.",
                    ],
                  });
                } else {
                  console.log(err.message);
                  reject({ messages: [err.message] });
                }
              });
          });
        },
        GetOrganisationUsers({ rootState }, data) {
          return new Promise((resolve, reject) => {
            axios
              .get("/organisations/users/" + data.id + "/", {
                headers: {
                  Authorization: `Bearer ${
                    rootState.User.token || localStorage.MicronToken
                  }`,
                },
              })
              .then(({ data }) => {
                resolve(data);
              })
              .catch((err) => {
                if (err.response) {
                  reject(err.response.data);
                } else if (err.request) {
                  console.log(err.request);
                  reject({
                    messages: [
                      "Sorry, something wen't wrong with that request.",
                    ],
                  });
                } else {
                  console.log(err.message);
                  reject({ messages: [err.message] });
                }
              });
          });
        },
        GetBusinessInfo({ rootState }, data) {
          return new Promise((resolve, reject) => {
            axios
              .get(`/organisations/${data.id}/businesses/info/${data.bid}/`, {
                headers: {
                  Authorization: `Bearer ${
                    rootState.User.token || localStorage.MicronToken
                  }`,
                },
              })
              .then(({ data }) => {
                resolve(data);
              })
              .catch((err) => {
                if (err.response) {
                  reject(err.response.data);
                } else if (err.request) {
                  console.log(err.request);
                  reject({
                    messages: [
                      "Sorry, something wen't wrong with that request.",
                    ],
                  });
                } else {
                  console.log(err.message);
                  reject({ messages: [err.message] });
                }
              });
          });
        },
        GetBusinessUsers({ rootState }, data) {
          return new Promise((resolve, reject) => {
            axios
              .get(`/organisations/${data.id}/businesses/users/${data.bid}/`, {
                headers: {
                  Authorization: `Bearer ${
                    rootState.User.token || localStorage.MicronToken
                  }`,
                },
              })
              .then(({ data }) => {
                resolve(data);
              })
              .catch((err) => {
                if (err.response) {
                  reject(err.response.data);
                } else if (err.request) {
                  console.log(err.request);
                  reject({
                    messages: [
                      "Sorry, something wen't wrong with that request.",
                    ],
                  });
                } else {
                  console.log(err.message);
                  reject({ messages: [err.message] });
                }
              });
          });
        },
        UpdateBusinessInfo({ rootState }, data) {
          return new Promise((resolve, reject) => {
            var formData = new FormData();
            if (data.change_logo !== false) {
              formData.append("logo", data.logo, "logo.png");
              formData.append("address", data.address);
              formData.append("postcode", data.postcode);
              formData.append("contact_email", data.contact_email);
              formData.append("contact_telephone", data.contact_telephone);
            } else {
              formData.append("address", data.address);
              formData.append("postcode", data.postcode);
              formData.append("contact_email", data.contact_email);
              formData.append("contact_telephone", data.contact_telephone);
            }
            console.log(formData);
            axios
              .put(
                `/organisations/${data.id}/businesses/info/${data.bid}/`,
                formData,
                {
                  headers: {
                    Authorization: `Bearer ${
                      rootState.User.token || localStorage.MicronToken
                    }`,
                    "Content-Type": "multipart/form-data",
                  },
                }
              )
              .then(({ data }) => {
                resolve(data);
              })
              .catch((err) => {
                if (err.response) {
                  reject(err.response.data);
                } else if (err.request) {
                  console.log(err.request);
                  reject({
                    messages: [
                      "Sorry, something wen't wrong with that request.",
                    ],
                  });
                } else {
                  console.log(err.message);
                  reject({ messages: [err.message] });
                }
              });
          });
        },
        RemoveUserFromBusiness({ rootState }, data) {
          return new Promise((resolve, reject) => {
            axios
              .put(
                `/organisations/${data.id}/businesses/users/remove/${data.bid}/`,
                data,
                {
                  headers: {
                    Authorization: `Bearer ${
                      rootState.User.token || localStorage.MicronToken
                    }`,
                  },
                }
              )
              .then(({ data }) => {
                resolve(data);
              })
              .catch((err) => {
                if (err.response) {
                  reject(err.response.data);
                } else if (err.request) {
                  console.log(err.request);
                  reject({
                    messages: [
                      "Sorry, something wen't wrong with that request.",
                    ],
                  });
                } else {
                  console.log(err.message);
                  reject({ messages: [err.message] });
                }
              });
          });
        },
        InviteBusinessUser({ rootState }, data) {
          return new Promise((resolve, reject) => {
            axios
              .post(
                `/organisations/${data.id}/businesses/users/invite/${data.bid}/`,
                data,
                {
                  headers: {
                    Authorization: `Bearer ${
                      rootState.User.token || localStorage.MicronToken
                    }`,
                  },
                }
              )
              .then(({ data }) => {
                resolve(data);
              })
              .catch((err) => {
                if (err.response) {
                  reject(err.response.data);
                } else if (err.request) {
                  console.log(err.request);
                  reject({
                    messages: [
                      "Sorry, something wen't wrong with that request.",
                    ],
                  });
                } else {
                  console.log(err.message);
                  reject({ messages: [err.message] });
                }
              });
          });
        },
        RemoveUserFromOrganisation({ rootState }, data) {
          return new Promise((resolve, reject) => {
            axios
              .put(`/organisations/${data.id}/users/remove/`, data, {
                headers: {
                  Authorization: `Bearer ${
                    rootState.User.token || localStorage.MicronToken
                  }`,
                },
              })
              .then(({ data }) => {
                resolve(data);
              })
              .catch((err) => {
                if (err.response) {
                  reject(err.response.data);
                } else if (err.request) {
                  console.log(err.request);
                  reject({
                    messages: [
                      "Sorry, something wen't wrong with that request.",
                    ],
                  });
                } else {
                  console.log(err.message);
                  reject({ messages: [err.message] });
                }
              });
          });
        },
        InviteOrganisationUser({ rootState }, data) {
          return new Promise((resolve, reject) => {
            axios
              .post(`/organisations/${data.id}/users/invite/`, data, {
                headers: {
                  Authorization: `Bearer ${
                    rootState.User.token || localStorage.MicronToken
                  }`,
                },
              })
              .then(({ data }) => {
                resolve(data);
              })
              .catch((err) => {
                if (err.response) {
                  reject(err.response.data);
                } else if (err.request) {
                  console.log(err.request);
                  reject({
                    messages: [
                      "Sorry, something wen't wrong with that request.",
                    ],
                  });
                } else {
                  console.log(err.message);
                  reject({ messages: [err.message] });
                }
              });
          });
        },
        CreateABusiness({ rootState }, data) {
          return new Promise((resolve, reject) => {
            axios
              .post(`/organisations/${data.id}/businesses/create/`, data, {
                headers: {
                  Authorization: `Bearer ${
                    rootState.User.token || localStorage.MicronToken
                  }`,
                },
              })
              .then(({ data }) => {
                resolve(data);
              })
              .catch((err) => {
                if (err.response) {
                  reject(err.response.data);
                } else if (err.request) {
                  console.log(err.request);
                  reject({
                    messages: [
                      "Sorry, something wen't wrong with that request.",
                    ],
                  });
                } else {
                  console.log(err.message);
                  reject({ messages: [err.message] });
                }
              });
          });
        },
        AddCreditsToBusiness({ rootState }, data) {
          return new Promise((resolve, reject) => {
            axios
              .post(
                `/organisations/${data.id}/businesses/${data.bid}/addcredits/`,
                data,
                {
                  headers: {
                    Authorization: `Bearer ${
                      rootState.User.token || localStorage.MicronToken
                    }`,
                  },
                }
              )
              .then(({ data }) => {
                resolve(data);
              })
              .catch((err) => {
                if (err.response) {
                  reject(err.response.data);
                } else if (err.request) {
                  console.log(err.request);
                  reject({
                    messages: [
                      "Sorry, something wen't wrong with that request.",
                    ],
                  });
                } else {
                  console.log(err.message);
                  reject({ messages: [err.message] });
                }
              });
          });
        },
      },
    },
    Forms:{
      namespaced: true,
      state: {},
      actions:{
        Formcheck({ rootState },formnumber){
          console.log(rootState);
          return new Promise((resolve,reject) =>{
            
            axios.get(`/form/formcheck/${formnumber.number}`)
            .then(({data})=>{
              resolve(data);
              })
            .catch((err) => {
              if (err.response) {
                reject(err.response.data);
              } else if (err.request) {
                console.log(err.request);
                reject({
                  messages: [
                    "Sorry, something wen't wrong with that request.",
                  ],
                });
              } else {
                console.log(err.message);
                reject({ messages: [err.message] });
              }
            });            

          });
        },
        Addresscheck({rootState},useraddress){
          return new Promise((resolve,reject) =>{
            console.log(rootState);
            axios.get(`/form/addresscheck/${useraddress}`)
            .then(({data})=>{
              console.log(data);
              resolve(data);
            })
            .catch((err)=>{
              reject(err.response.data);
            })
          })
        },
        FormUpload({rootState},formfilledsubmit){
          console.log(rootState);
          console.log(formfilledsubmit);
          return new Promise((resolve,reject)=>{
            axios
              .post(`/form/formupload/`,formfilledsubmit)
              .then(({data})=>{
                resolve(data);
                })
              .catch((err) => {
                if (err.response) {
                  reject(err.response.data);
                } else if (err.request) {
                  console.log(err.request);
                  reject({
                    messages: [
                      "Sorry, something wen't wrong with that request.",
                    ],
                  });
                } else {
                  console.log(err.message);
                  reject({ messages: [err.message] });
                }
              });

              })

        },
        PostcodeCheck({rootState},postcode){
          return new Promise ((resolve,reject)=>{
            console.log(rootState,postcode);
            axios.get(`/form/postcodecheck/${postcode}`)
            .then(({data})=>{
              // console.log(data);
              resolve(data);
            })
            .catch((err)=>{
              reject(err.response.data);
            })
          })
        }
      }

    },
  },
});
