import { createApp } from 'vue'
// import Vuex from 'vuex'
import App from './App.vue'
import router from './router'
import { store } from './store'
import CKEditor from '@ckeditor/ckeditor5-vue';


router.beforeEach(async (to, from, next) => {
    document.title = `Micron Kit | ${to.meta.title}`
    console.log(`Going to ${to.name} from ${from.name}`);
    const user = store.state["User"].gotinfo;
    if (user == false && localStorage.MicronToken) {
        store.dispatch('User/UserInformation')
    }
    next();
})

createApp(App)
    .use(store)
    .use(router)
    .use(CKEditor)
    .mount('#app')
